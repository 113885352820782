
footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #111;
    /* height: auto;
    width: 100vw; */
    height: 300px;
    width: 100%;

    padding-top: 10px;
    color: #fff;
}

.footer-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.footer-content h3 {
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 3rem;
}

.footer-content p {
    max-width: 500px;
    margin: 10px auto;
    line-height: 28px;
    font-size: 14px;
    color: #cacdd2;
}

.socials {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0 3rem 0;
}

.socials li {
    margin: 0 10px;
}

.socials a {
    text-decoration: none;
    color: #fff;
    border: 1.1px solid white;
    padding: 5px;

    border-radius: 50%; 
}

.socials a i {
    font-size: 1.1rem;
    width: 20px;

    transition: color .4s ease;
}

.socials a:hover {
    color: #44bae8;
}

.footer-bottom {
    background: #000;
    width: 100vw;
    padding: 20px;
    padding-bottom: 40px;
    text-align: center;
}

.footer-bottom p {
    display: block;
    text-align: center;
    color: rgb(169, 168, 168);
    font-size: 12px;
}

.footer-bottom p a {
    color: #44bae8;
    font-size: 12px;
    text-decoration: none;
}

.footer-bottom span {
    text-transform: uppercase;
    opacity: .4;
    font-weight: 200;
}

.footer-menu {
    text-align: center;
}

.footer-menu ul {
    display: block;
}

.footer-menu ul li {
    padding-right: 10px;
    display: block;
}

.footer-menu ul li a {
    color: #cfd2d6;
    text-decoration: none;
}

.footer-menu ul li a:hover {
    color: #27bcda;
}


